import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import c from './ListingForm.module.scss';
import {
  AutoComplete,
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
} from 'antd';
import {
  LeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  ReloadOutlined,
  DeleteOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {
  apiListingSingle,
  apiListingSingleCreate,
  apiListingSingleEdit,
} from '../../../store/slices/listingSlice/listingAPI';
import Loader from '../../Loader/Loader';
import moment from 'moment';
import { toast } from 'react-toastify';
import Comments from '../../Comments/Comments';
import Modal from '../../Modal/Modal';
import {
  apiRequestSelectOptions,
  apiSearchUser,
} from '../../../store/slices/requestSlice/requestAPI';
import ImageUploader from '../../ImageUploader/ImageUploader';
import { CopyToClipboard } from '../../../helper/copyToClipboard';
import { amountPrecision } from '../../../helper/utl';
import NumericInput from '../../Ui/NumericInput/NumericInput';

const { TextArea } = Input;

const ListingEdit = ({ create }) => {
  const params = useParams();
  const { Option } = Select;
  const navigate = useNavigate();
  const [singleData, setSingleData] = useState({
    relationWithCoin: {},
    code: '',
    name: '',
    description: '',
    logoName: '',
    airDrop: 0,
    airDropStatus: false,
    airDropTxId: '',
    status: 'new',
    website: '',
    explorer: '',
    contactEmail: '',
    telegram: '',
    forkedFrom: '',
    openSource: false,
    blockchain: '',
    github: '',
    contractAddress: '',
    exchangeList: [],
    trackingSites: [],
    communityChannels: [],
    currentlyTradingOn: '',
    listedDate: Date.now(),
    listingType: '',
    totalSupply: 0,
    currentPrice: 0,
    circulatingSupply: 0,
    isPresale: null,
    isIdentified: null,
    listingRequestType: null,
    additionalInfo: '',
    depositWithdrawalActivationDate: Date.now(),
    tradeActivationDate: Date.now(),
    listingPackage: '',
    paymentAmount: 0,
    pairs: [],
    paymentTransactionId: '',
    addToVoting: null,
  });

  const [selectOptions, setSelectOptions] = useState({});
  const [loading, setLoading] = useState(true);
  const [votingReq, setVotingReq] = useState(false);
  const [listingReq, setListingReq] = useState(false);
  const [relationWithCoinOption, setRelationWithCoinOption] = useState();
  const [listingStatus, setListingStatus] = useState();
  const [rejectPopup, setRejectPopup] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [rejectReasonOption, setRejectReasonOption] = useState('');
  const [form] = Form.useForm();
  const [userComments, setUserComments] = useState([]);
  const [systemComments, setSystemComments] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userFreeAccountList, setUserFreeAccountList] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedUserFreeAccount, setSelectedUserFreeAccount] = useState();
  const [logoUploadImg, setLogoUploadImg] = useState(null);
  const [logoUploadImgAdmin, setLogoUploadImgAdmin] = useState(null);
  const [logoUploadErrorUser, setLogoUploadErrorUser] = useState(false);
  const [logoUploadErrorAdmin, setLogoUploadErrorAdmin] = useState(false);
  useEffect(() => {
    if (listingStatus === 'rejected') {
      setRejectPopup(true);
      setRejectReason(selectOptions?.rejectReason[0].value);
      setRejectReasonOption(selectOptions?.rejectReason[0].value);
    } else {
      setRejectPopup(false);
    }
  }, [listingStatus]);

  useEffect(async () => {
    setLoading(true);
    await apiRequestSelectOptions()
      .then(setSelectOptions)
      .catch(e => console.log(e));
    if (!create) {
      await getSingleData();
    }
    setLoading(false);
  }, []);

  const onBlur = () => {};

  const validateMsg = (type, value) => {
    let message = '';
    if (type === 'min') {
      message = `Should be more than ${value}`;
      return message;
    } else if (type === 'max') {
      message = `should not be more than ${value}`;
      return message;
    }
  };

  const validateMinAmount = min => {
    return (_, value) => {
      if (value >= min) {
        return Promise.resolve();
      } else {
        return Promise.reject(_.message);
      }
    };
  };

  const validateMaxAmount = max => {
    return (_, value) => {
      if (value <= max) {
        return Promise.resolve();
      } else {
        return Promise.reject(_.message);
      }
    };
  };

  const getSingleData = async () => {
    setLoading(true);
    await apiListingSingle(params.id)
      .then(e => {
        setSingleData(e);
        setVotingReq(e.listingRequestType.voting);
        setListingReq(e.listingRequestType.listing);
        setRelationWithCoinOption(e.relationWithCoin.relation);
        setLogoUploadImg({ image: e.logoName });
        setLogoUploadImgAdmin({ image: e.logoNameAdmin });
        setSelectedUser(e.user);
        setSelectedUserFreeAccount(e.tradingFeeFreeAccount);
        setRejectReason(e.rejectReason);

        if (e.comments?.length) {
          setUserComments(e.comments.filter(item => item.type === 'user'));
          setSystemComments(e.comments.filter(item => item.type === 'system'));
        }
      })
      .finally(() => setLoading(false));
  };

  const editData = async values => {
    const depositWithdrawalActivationDate =
      moment(values.depositWithdrawalActivationDate).unix() * 1000;
    const listedDate = moment(values.listedDate).unix() * 1000;
    const addedToVotingDate = moment(values.addedToVotingDate).unix() * 1000;
    const tradeActivationDate =
      moment(values.tradeActivationDate).unix() * 1000;

    const data = {
      ...singleData,
      ...values,
      depositWithdrawalActivationDate,
      listedDate,
      addedToVotingDate,
      tradeActivationDate,
      logoName: logoUploadImg ? logoUploadImg.image : '',
      logoNameAdmin: logoUploadImgAdmin?.image ? logoUploadImgAdmin?.image : '',
      relationWithCoin: !values.relationWithCoin.relation
        ? undefined
        : values.relationWithCoin,
      currentRoundVotes: undefined,
      totalVotes: undefined,
      badges: undefined,
      trust: undefined,
      mistrust: undefined,
      comments: undefined,
      updated: undefined,
      created: undefined,
      id: undefined,
      __v: undefined,
      totalPaymentAmount: undefined,
      paymentCurrency: undefined,
      price: undefined,
      priceChangeIn24h: undefined,
      marketCap: undefined,
      lowestPriceIn24h: undefined,
      highestPriceIn24h: undefined,
      paymentAmount: values.paymentAmount > 0 ? values.paymentAmount : 0,
      syncWithVoting: undefined,
    };
    data.user = selectedUser;
    data.tradingFeeFreeAccount = selectedUserFreeAccount?.email
      ? selectedUserFreeAccount
      : null;

    if (create) {
      await apiListingSingleCreate({
        ...data,
      })
        .then(async e => {
          navigate(`/listing/${e.id}`);
          toast.success('Save');
        })
        .catch(e => toast.error(e.message));
    } else {
      data.order = undefined;
      data.orderId = undefined;
      await apiListingSingleEdit(params.id, {
        ...data,
        rejectReason,
      })
        .then(async e => {
          form.setFieldsValue({ ...e });
          await getSingleData();
          toast.success('Save');
        })
        .catch(e => toast.error(e.message));
    }
  };

  const onSearch = async (searchText, freeAccount) => {
    if (searchText.length < 3) {
      setUserList([]);
      return;
    }
    await apiSearchUser(searchText).then(e => {
      freeAccount
        ? setUserFreeAccountList(
            e.users.map(item => {
              return {
                value: item.username + '(' + item.email + ')',
                email: item.email,
                id: item.id,
                role: item.role,
                username: item.username,
              };
            }),
          )
        : setUserList(
            e.users.map(item => {
              return {
                value: item.username + '(' + item.email + ')',
                email: item.email,
                id: item.id,
                role: item.role,
                username: item.username,
              };
            }),
          );
    });
  };

  const setRequestType = (e, type) => {
    const request = form.getFieldValue('listingRequestType');
    if (type === 'voting') {
      form.setFieldsValue({
        listingRequestType: { voting: e.target.checked },
      });
      setVotingReq(e.target.checked);
      if (typeof request?.listing === 'undefined') {
        form.setFieldsValue({
          listingRequestType: { listing: false },
        });
      }
    } else {
      form.setFieldsValue({
        listingRequestType: { listing: e.target.checked },
      });
      setListingReq(e.target.checked);
      if (typeof request?.voting === 'undefined') {
        form.setFieldsValue({
          listingRequestType: { voting: false },
        });
      }
    }
    const getListingRequest = form.getFieldValue('listingRequestType');
    if (
      getListingRequest?.voting === false &&
      getListingRequest?.listing === false
    ) {
      form.setFieldsValue({
        listingRequestType: null,
      });
    }
  };

  const onSelect = (_, data, freeAccount) => {
    delete data.value;
    freeAccount ? setSelectedUserFreeAccount(data) : setSelectedUser(data);
  };

  return (
    <>
      <h1 className="title">ListingList Single</h1>
      <div className={c.listingEdit}>
        {loading ? (
          <Loader fixed={false} />
        ) : (
          <div>
            <Form
              form={form}
              name="stakingEdit"
              onFinish={editData}
              onValuesChange={() => {}}
              autoComplete="off"
              scrollToFirstError>
              <div className={c.single}>
                <div className={c.stakingInfo}>
                  <div>
                    <div className={c.stakingInfo__buttons}>
                      <Button
                        type="primary"
                        icon={<LeftOutlined />}
                        onClick={() => navigate(-1)}>
                        Back
                      </Button>
                      <Button
                        type="primary"
                        icon={<ReloadOutlined />}
                        onClick={() => {
                          form.resetFields();
                          setListingReq(singleData.listingRequestType.listing);
                          setVotingReq(singleData.listingRequestType.voting);
                        }}>
                        Reset
                      </Button>
                      <Form.Item style={{ marginBottom: 0 }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          icon={<SaveOutlined />}>
                          Save
                        </Button>
                      </Form.Item>
                    </div>
                    <div className={c.form__inner}>
                      <div className={c.singleGroup__title}>
                        Coin/Token information
                        {!create && singleData.syncWithVoting
                          ? '(Syncing)'
                          : ''}
                      </div>
                      <div className={c.form__list}>
                        <>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              label="User">
                              <AutoComplete
                                options={userList}
                                notFoundContent={'Nothing found'}
                                onSelect={(e, data) => onSelect(e, data)}
                                onSearch={e => onSearch(e)}
                                placeholder="Search User"
                                defaultValue={
                                  singleData?.user
                                    ? singleData.user.username +
                                      '(' +
                                      singleData.user.email +
                                      ')'
                                    : ''
                                }
                              />
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="name"
                              label="Name"
                              initialValue={singleData.name}
                              rules={[
                                {
                                  required: true,
                                  message: 'Required',
                                  whitespace: false,
                                },
                              ]}>
                              <Input
                                style={{ width: '100%' }}
                                onDoubleClick={CopyToClipboard}
                              />
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="code"
                              label="Symbol"
                              initialValue={singleData.code}
                              rules={[
                                {
                                  required: true,
                                  message: 'Required',
                                  whitespace: true,
                                },
                                {
                                  pattern: /^[a-zA-Z0-9]*$/,
                                  message:
                                    'only lowercase/uppercase letters and numbers are valid',
                                },
                              ]}>
                              <Input onDoubleClick={CopyToClipboard} />
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="contactEmail"
                              label="Contact Email"
                              initialValue={singleData.contactEmail}
                              rules={[
                                {
                                  type: 'email',
                                },
                              ]}>
                              <Input onDoubleClick={CopyToClipboard} />
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="telegram"
                              label="Telegram"
                              initialValue={singleData.telegram}
                              rules={[
                                {
                                  message: 'Required',
                                  whitespace: true,
                                },
                              ]}>
                              <Input onDoubleClick={CopyToClipboard} />
                            </Form.Item>
                          </div>
                        </>
                        <>
                          <div className={c.stakingInfo__item}>
                            <div className={c.aside__itemTitle}>
                              <span
                                style={{
                                  display: 'inline-block',
                                  fontFamily: 'SimSun, sans-serif',
                                  color: '#ff4d4f',
                                  fontSize: '14px',
                                  marginRight: '5px',
                                }}></span>
                              Relations with the C/T
                            </div>
                            <Form.Item style={{ width: '100%' }}>
                              <Input.Group
                                style={{ display: 'flex', width: '100%' }}>
                                <Form.Item
                                  style={{
                                    width: '100%',
                                    marginBottom: 0,
                                  }}
                                  initialValue={
                                    singleData.relationWithCoin.relation
                                  }
                                  name={['relationWithCoin', 'relation']}>
                                  <Select
                                    placeholder="Select province"
                                    style={{ width: '100%' }}
                                    onChange={e => {
                                      setRelationWithCoinOption(e);
                                      form.setFieldsValue({
                                        relationWithCoin: { value: null },
                                      });
                                    }}>
                                    {selectOptions?.relationWithCoin?.map(
                                      item => (
                                        <Option
                                          key={item.value}
                                          value={item.value}>
                                          {item.name}
                                        </Option>
                                      ),
                                    )}
                                  </Select>
                                </Form.Item>
                                {relationWithCoinOption === 'other' && (
                                  <Form.Item
                                    style={{
                                      width: '100%',
                                      marginBottom: 0,
                                    }}
                                    name={['relationWithCoin', 'value']}
                                    initialValue={
                                      singleData.relationWithCoin.value
                                    }
                                    rules={[
                                      {
                                        required:
                                          relationWithCoinOption === 'other',
                                        message: 'Required',
                                      },
                                    ]}>
                                    <Input
                                      style={{ width: '100%' }}
                                      placeholder="Input street"
                                    />
                                  </Form.Item>
                                )}
                              </Input.Group>
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="forkedFrom"
                              label="Which Coin are you forked from"
                              initialValue={singleData.forkedFrom}
                              rules={[
                                {
                                  required: false,
                                  whitespace: true,
                                },
                              ]}>
                              <Input onDoubleClick={CopyToClipboard} />
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="blockchain"
                              label="Chose your blockchain"
                              initialValue={singleData.blockchain}>
                              <Select placeholder="select your blockchain">
                                {selectOptions?.blockchain?.map(item => (
                                  <Option key={item.value} value={item.value}>
                                    {item.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="openSource"
                              label="Is your project open-source?"
                              initialValue={singleData.openSource}>
                              <Radio.Group>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </div>
                        </>
                        <>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="website"
                              label="Website link "
                              initialValue={singleData.website}>
                              <Input
                                style={{ width: '100%' }}
                                onDoubleClick={CopyToClipboard}
                              />
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="github"
                              label="GitHub repo"
                              initialValue={singleData.github}
                              rules={[
                                {
                                  required: false,
                                },
                              ]}>
                              <Input
                                style={{ width: '100%' }}
                                onDoubleClick={CopyToClipboard}
                              />
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="contractAddress"
                              label="Smart-contract address / Asset ID"
                              initialValue={singleData.contractAddress}
                              rules={[
                                {
                                  required: false,
                                  whitespace: false,
                                },
                              ]}>
                              <Input
                                style={{ width: '100%' }}
                                onDoubleClick={CopyToClipboard}
                              />
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="explorer"
                              label="Explorer link"
                              initialValue={singleData.explorer}>
                              <Input
                                style={{ width: '100%' }}
                                onDoubleClick={CopyToClipboard}
                              />
                            </Form.Item>
                          </div>
                        </>
                        <>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="totalSupply"
                              label="Total Coin/Token supply"
                              initialValue={singleData.totalSupply}
                              validateTrigger="onBlur"
                              rules={[
                                {
                                  required: false,
                                },
                                {
                                  message: validateMsg('min', 0),
                                  validator: validateMinAmount(0),
                                },
                                {
                                  message: validateMsg(
                                    'max',
                                    999999999999999999999,
                                  ),
                                  validator:
                                    validateMaxAmount(999999999999999999999),
                                },
                              ]}>
                              <NumericInput
                                precision={6}
                                onDoubleClick={CopyToClipboard}
                                onBlur={currentValue =>
                                  onBlur(currentValue, 'totalSupply')
                                }
                              />
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="currentPrice"
                              label="Current Coin/Token price"
                              initialValue={singleData.currentPrice}
                              validateTrigger="onBlur"
                              rules={[
                                {
                                  required: false,
                                },
                                {
                                  message: validateMsg('min', 0),
                                  validator: validateMinAmount(0),
                                },
                                {
                                  message: validateMsg('max', 1000000000),
                                  validator: validateMaxAmount(1000000000),
                                },
                              ]}>
                              <NumericInput
                                precision={10}
                                onDoubleClick={CopyToClipboard}
                                onBlur={currentValue =>
                                  onBlur(currentValue, 'currentPrice')
                                }
                              />
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="circulatingSupply"
                              label="Circulating Supply:"
                              validateTrigger="onBlur"
                              initialValue={singleData.circulatingSupply}
                              rules={[
                                {
                                  required: true,
                                  message: 'Required',
                                },
                                {
                                  message: validateMsg('min', 10 ** -6),
                                  validator: validateMinAmount(10 ** -6),
                                },
                                {
                                  message: validateMsg(
                                    'max',
                                    999999999999999999999,
                                  ),
                                  validator:
                                    validateMaxAmount(999999999999999999999),
                                },
                              ]}>
                              <NumericInput
                                precision={6}
                                onDoubleClick={CopyToClipboard}
                                onBlur={currentValue =>
                                  onBlur(currentValue, 'circulatingSupply')
                                }
                              />
                            </Form.Item>
                          </div>
                        </>
                        <>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="isPresale"
                              label="Is this a Presale Project?"
                              initialValue={singleData.isPresale}>
                              <Radio.Group>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              valuePropName={'checked'}
                              name={'listingRequestType'}
                              initialValue={singleData.listingRequestType}
                              label={'Choose at least one of the followings:'}
                              style={{
                                flexDirection: 'column',
                              }}
                              rules={[
                                {
                                  required: true,
                                  message: 'Required',
                                },
                              ]}>
                              <>
                                <Checkbox
                                  checked={votingReq}
                                  onChange={e => setRequestType(e, 'voting')}>
                                  <span>{`Place my coin/token for`}</span>
                                  <a
                                    style={{ marginLeft: '5px' }}
                                    target={'_blank'}
                                    href={'https://bololex.com/voting'}
                                    rel="noreferrer">
                                    {'Voting'}
                                  </a>
                                </Checkbox>
                                <Checkbox
                                  checked={listingReq}
                                  onChange={e => setRequestType(e, 'listing')}>
                                  <span>{'List my coin in'}</span>
                                  <a
                                    style={{ marginLeft: '5px' }}
                                    target={'_blank'}
                                    href={'https://bololex.com/trading'}
                                    rel="noreferrer">
                                    {'Bololex'}
                                  </a>
                                </Checkbox>
                              </>
                            </Form.Item>
                          </div>
                          <div
                            className={c.stakingInfo__item}
                            style={{ width: '48.6%' }}>
                            <Form.Item
                              name={'currentlyTradingOn'}
                              initialValue={singleData.currentlyTradingOn}
                              label={
                                'Exchanges where your Coin/Token can be traded on (Old field)'
                              }
                              style={{
                                flexDirection: 'column',
                              }}>
                              <Input />
                            </Form.Item>
                          </div>
                        </>
                        <>
                          <div
                            className={c.stakingInfo__item}
                            style={{ width: '48.6%' }}>
                            <div className={c.aside__itemTitle}>
                              Exchanges where your Coin/Token can be traded on
                            </div>
                            <Form.List
                              name="exchangeList"
                              initialValue={singleData.exchangeList}>
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                      key={`exchangeList-${key}`}
                                      align="baseline"
                                      style={{
                                        width: '100%',
                                        alignItems: 'center',
                                      }}>
                                      <Form.Item
                                        {...restField}
                                        label="Exchange"
                                        style={{
                                          flexDirection: 'column',
                                        }}
                                        name={[name, 'site']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Missing sight',
                                          },
                                        ]}>
                                        <Select placeholder="Select">
                                          {selectOptions?.exchangeList?.map(
                                            item => (
                                              <Option
                                                key={item.value}
                                                value={item.value}>
                                                {item.name}
                                              </Option>
                                            ),
                                          )}
                                        </Select>
                                      </Form.Item>
                                      <Form.Item
                                        {...restField}
                                        style={{
                                          flexDirection: 'column',
                                        }}
                                        validateTrigger="onBlur"
                                        label="URL"
                                        name={[name, 'url']}
                                        rules={[
                                          {
                                            type: 'url',
                                            message: 'Not a valid url',
                                          },
                                          {
                                            required: true,
                                            message: 'Missing url',
                                          },
                                        ]}>
                                        <Input
                                          onDoubleClick={e =>
                                            CopyToClipboard(e)
                                          }
                                        />
                                      </Form.Item>
                                      <MinusCircleOutlined
                                        onClick={() => remove(name)}
                                      />
                                    </Space>
                                  ))}
                                  <Form.Item>
                                    <Button
                                      type="dashed"
                                      onClick={() => add()}
                                      block
                                      icon={<PlusOutlined />}>
                                      Add sites
                                    </Button>
                                  </Form.Item>
                                </>
                              )}
                            </Form.List>
                          </div>
                          <div
                            className={c.stakingInfo__item}
                            style={{ width: '48.6%' }}>
                            <div className={c.aside__itemTitle}>
                              Link to CMC or CG
                            </div>
                            <Form.List
                              name="trackingSites"
                              initialValue={singleData.trackingSites}>
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                      key={`trackingSites-${key}`}
                                      align="baseline"
                                      style={{
                                        width: '100%',
                                        alignItems: 'center',
                                      }}>
                                      <Form.Item
                                        {...restField}
                                        label="Site"
                                        style={{
                                          flexDirection: 'column',
                                        }}
                                        name={[name, 'site']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Missing sight',
                                          },
                                        ]}>
                                        <Select>
                                          {selectOptions?.trackingSites?.map(
                                            item => (
                                              <Option
                                                key={item.value}
                                                value={item.value}>
                                                {item.name}
                                              </Option>
                                            ),
                                          )}
                                        </Select>
                                      </Form.Item>
                                      <Form.Item
                                        {...restField}
                                        style={{
                                          flexDirection: 'column',
                                        }}
                                        validateTrigger="onBlur"
                                        label="URL"
                                        name={[name, 'url']}
                                        rules={[
                                          {
                                            type: 'url',
                                            message: 'Not a valid url',
                                          },
                                          {
                                            required: true,
                                            message: 'Missing url',
                                          },
                                        ]}>
                                        <Input
                                          onDoubleClick={e =>
                                            CopyToClipboard(e)
                                          }
                                        />
                                      </Form.Item>
                                      <MinusCircleOutlined
                                        onClick={() => remove(name)}
                                      />
                                    </Space>
                                  ))}
                                  <Form.Item>
                                    <Button
                                      type="dashed"
                                      onClick={() => add()}
                                      block
                                      icon={<PlusOutlined />}>
                                      Add sites
                                    </Button>
                                  </Form.Item>
                                </>
                              )}
                            </Form.List>
                          </div>
                          <div
                            className={c.stakingInfo__item}
                            style={{ width: '48.6%' }}>
                            <div className={c.aside__itemTitle}>
                              Community channels and links
                            </div>
                            <Form.List
                              name="communityChannels"
                              initialValue={singleData.communityChannels}>
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                      key={`communityChannels-${key}`}
                                      align="baseline"
                                      style={{
                                        width: '100%',
                                        alignItems: 'center',
                                      }}>
                                      <Form.Item
                                        {...restField}
                                        label="Community"
                                        style={{
                                          flexDirection: 'column',
                                        }}
                                        name={[name, 'community']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Missing sight',
                                          },
                                        ]}>
                                        <Select>
                                          {selectOptions?.communityChannels?.map(
                                            item => (
                                              <Option
                                                key={item.value}
                                                value={item.value}>
                                                {item.name}
                                              </Option>
                                            ),
                                          )}
                                        </Select>
                                      </Form.Item>
                                      <Form.Item
                                        {...restField}
                                        style={{
                                          flexDirection: 'column',
                                        }}
                                        label="URL"
                                        validateTrigger="onBlur"
                                        name={[name, 'url']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Missing url',
                                          },
                                          {
                                            type: 'url',
                                            message: 'Not a valid url',
                                          },
                                        ]}>
                                        <Input
                                          onDoubleClick={e =>
                                            CopyToClipboard(e)
                                          }
                                        />
                                      </Form.Item>
                                      <MinusCircleOutlined
                                        onClick={() => remove(name)}
                                      />
                                    </Space>
                                  ))}
                                  <Form.Item>
                                    <Button
                                      type="dashed"
                                      onClick={() => add()}
                                      block
                                      icon={<PlusOutlined />}>
                                      Add sites
                                    </Button>
                                  </Form.Item>
                                </>
                              )}
                            </Form.List>
                          </div>
                        </>
                        <>
                          <div
                            className={c.stakingInfo__item}
                            style={{ width: '100%' }}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="description"
                              label="Coin/Token Description, note that this text will be used in the Coin/Token page"
                              initialValue={singleData.description}>
                              <TextArea
                                placeholder="Controlled autosize"
                                autoSize={{ minRows: 3, maxRows: 5 }}
                              />
                            </Form.Item>
                          </div>
                        </>
                        <>
                          <div
                            className={c.stakingInfo__item}
                            style={{ width: '100%' }}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="additionalInfo"
                              label="Any other information that you would like to provide?"
                              initialValue={singleData.additionalInfo}>
                              <TextArea
                                initialValue={singleData.name}
                                placeholder="Controlled autosize"
                                autoSize={{ minRows: 3, maxRows: 5 }}
                              />
                            </Form.Item>
                          </div>
                        </>
                        <>
                          <div
                            className={c.stakingInfo__item}
                            style={{ width: '100%' }}>
                            <div className={c.singleGroup__title}>Logo</div>
                            <ImageUploader
                              logoUploadImg={logoUploadImg}
                              setLogoUploadImg={setLogoUploadImg}
                              type={'user'}
                              logoUploadError={logoUploadErrorUser}
                              setLogoUploadError={setLogoUploadErrorUser}
                            />
                          </div>
                        </>
                      </div>
                    </div>
                    <div className={c.form__inner}>
                      <div className={c.singleGroup__title}>
                        Additional information
                      </div>
                      <div className={c.form__list}>
                        <>
                          <div
                            className={c.stakingInfo__item}
                            style={{ width: '48.6%' }}>
                            <div className={c.aside__itemTitle}>
                              Chosen pairs
                            </div>
                            <Form.List
                              name="pairs"
                              initialValue={singleData.pairs}>
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                      key={key}
                                      align="baseline"
                                      style={{
                                        width: '100%',
                                        alignItems: 'center',
                                      }}>
                                      <Form.Item
                                        {...restField}
                                        label="Pair"
                                        style={{
                                          flexDirection: 'column',
                                        }}
                                        name={[name, 'name']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Missing sight',
                                          },
                                        ]}>
                                        <Select>
                                          {selectOptions?.availableCurrencies?.map(
                                            item => (
                                              <Option key={item} value={item}>
                                                {item}
                                              </Option>
                                            ),
                                          )}
                                        </Select>
                                      </Form.Item>
                                      <Form.Item
                                        {...restField}
                                        style={{
                                          flexDirection: 'column',
                                        }}
                                        label="Url"
                                        name={[name, 'url']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Missing url',
                                          },
                                        ]}>
                                        <InputNumber
                                          style={{ width: '100%' }}
                                          onDoubleClick={e =>
                                            CopyToClipboard(e)
                                          }
                                        />
                                      </Form.Item>
                                      <MinusCircleOutlined
                                        onClick={() => remove(name)}
                                      />
                                    </Space>
                                  ))}
                                  <Form.Item>
                                    <Button
                                      type="dashed"
                                      onClick={() => add()}
                                      block
                                      icon={<PlusOutlined />}>
                                      Add sites
                                    </Button>
                                  </Form.Item>
                                </>
                              )}
                            </Form.List>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="paymentTransactionId"
                              label="Listing payment TXID"
                              initialValue={singleData.paymentTransactionId}>
                              <Input onDoubleClick={CopyToClipboard} />
                            </Form.Item>
                          </div>
                          <div
                            className={c.stakingInfo__item}
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                                width: '100%',
                                marginRight: '10px',
                              }}
                              label="Trading fee free account">
                              <AutoComplete
                                options={userFreeAccountList}
                                notFoundContent={'Nothing found'}
                                onSelect={(e, data) => onSelect(e, data, true)}
                                onSearch={e => onSearch(e, true)}
                                placeholder="Search User"
                                defaultValue={
                                  singleData?.tradingFeeFreeAccount?.username
                                    ? singleData.tradingFeeFreeAccount
                                        .username +
                                      '(' +
                                      singleData.tradingFeeFreeAccount.email +
                                      ')'
                                    : ''
                                }
                              />
                            </Form.Item>
                            <Button
                              style={{ marginTop: '7px' }}
                              type="primary"
                              htmlType={'submit'}
                              icon={<DeleteOutlined />}
                              onClick={() => {
                                setSelectedUserFreeAccount(null);
                                setUserFreeAccountList([]);
                              }}
                            />
                          </div>
                        </>
                        <>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              name="depositWithdrawalActivationDate"
                              initialValue={
                                singleData.depositWithdrawalActivationDate
                                  ? moment(
                                      singleData.depositWithdrawalActivationDate,
                                    )
                                  : singleData.depositWithdrawalActivationDate
                              }
                              style={{
                                flexDirection: 'column',
                              }}
                              label="Deposit & Withdraw activation date">
                              <DatePicker
                                format={'YYYY/MM/DD'}
                                style={{ width: '100%' }}
                              />
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              name="tradeActivationDate"
                              style={{
                                flexDirection: 'column',
                              }}
                              initialValue={
                                singleData.tradeActivationDate
                                  ? moment(singleData.tradeActivationDate)
                                  : singleData.tradeActivationDate
                              }
                              label="Trade activation date">
                              <DatePicker
                                format={'YYYY/MM/DD'}
                                style={{ width: '100%' }}
                              />
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              label={'Voting airdrop amount'}
                              name="airDrop"
                              validateTrigger="onBlur"
                              initialValue={singleData.airDrop}
                              rules={[
                                {
                                  required: false,
                                },
                                {
                                  message: validateMsg('min', 0),
                                  validator: validateMinAmount(0),
                                },
                                {
                                  message: validateMsg('max', 1000000000),
                                  validator: validateMaxAmount(1000000000),
                                },
                              ]}>
                              <NumericInput
                                precision={6}
                                onDoubleClick={CopyToClipboard}
                                onBlur={currentValue =>
                                  onBlur(currentValue, 'airDrop')
                                }
                              />
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="airDropTxId"
                              label="Voting airdrop TXID"
                              initialValue={singleData.airDropTxId}>
                              <Input onDoubleClick={CopyToClipboard} />
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="badge"
                              label="Coin verifications"
                              initialValue={singleData.badge}>
                              <Select showArrow mode="multiple">
                                {selectOptions?.badge?.map(item => (
                                  <Option key={item.value} value={item.value}>
                                    {item.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                          <div
                            className={c.stakingInfo__item}
                            style={{ flexGrow: '1', marginLeft: '35px' }}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="isIdentified"
                              label="Identified by Bololex"
                              initialValue={singleData.isIdentified}>
                              <Radio.Group>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </div>
                        </>
                        <>
                          <div
                            className={c.stakingInfo__item}
                            style={{ width: '100%' }}>
                            <div className={c.singleGroup__title}>
                              Logo Admin
                            </div>
                            <ImageUploader
                              logoUploadImg={logoUploadImgAdmin}
                              setLogoUploadImg={setLogoUploadImgAdmin}
                              type={'admin'}
                              code={singleData.code}
                              logoUploadError={logoUploadErrorAdmin}
                              setLogoUploadError={setLogoUploadErrorAdmin}
                            />
                          </div>
                        </>
                      </div>
                    </div>
                    <div className={c.form__inner}>
                      <div className={c.singleGroup__title}>
                        System settings
                      </div>
                      <div className={c.form__list}>
                        <div
                          className={c.stakingInfo__item}
                          style={{ width: '18%' }}>
                          <Form.Item
                            style={{
                              flexDirection: 'column',
                            }}
                            name="currentRoundVotes"
                            label="Round votes"
                            initialValue={singleData.currentRoundVotes}>
                            <InputNumber style={{ width: '100%' }} disabled />
                          </Form.Item>
                        </div>
                        <div
                          className={c.stakingInfo__item}
                          style={{ width: '18%' }}>
                          <Form.Item
                            style={{
                              flexDirection: 'column',
                            }}
                            name="totalVotes"
                            label="Total votes"
                            initialValue={singleData.totalVotes}>
                            <InputNumber style={{ width: '100%' }} disabled />
                          </Form.Item>
                        </div>
                        <div
                          className={c.stakingInfo__item}
                          style={{ width: '18%' }}>
                          <Form.Item
                            style={{
                              flexDirection: 'column',
                            }}
                            name="badges"
                            label="Badges"
                            initialValue={singleData.badges}>
                            <InputNumber style={{ width: '100%' }} disabled />
                          </Form.Item>
                        </div>
                        <div
                          className={c.stakingInfo__item}
                          style={{ width: '18%' }}>
                          <Form.Item
                            style={{
                              flexDirection: 'column',
                            }}
                            name="trust"
                            label="Trust"
                            initialValue={singleData.trust}>
                            <InputNumber style={{ width: '100%' }} disabled />
                          </Form.Item>
                        </div>
                        <div
                          className={c.stakingInfo__item}
                          style={{ width: '18%' }}>
                          <Form.Item
                            style={{
                              flexDirection: 'column',
                            }}
                            name="mistrust"
                            label="Mistrust"
                            initialValue={singleData.mistrust}>
                            <InputNumber style={{ width: '100%' }} disabled />
                          </Form.Item>
                        </div>
                      </div>
                      {/*{singleData.status === 'listed' && (
                        <div className={c.form__list}>
                          <div
                            className={c.stakingInfo__item}
                            style={{ width: '18%' }}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="price"
                              label="Price (USD)"
                              initialValue={singleData.price}>
                              <InputNumber style={{ width: '100%' }} disabled />
                            </Form.Item>
                          </div>
                          <div
                            className={c.stakingInfo__item}
                            style={{ width: '18%' }}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="priceChangeIn24h"
                              label="Price Change In 24h"
                              initialValue={singleData.priceChangeIn24h}>
                              <InputNumber style={{ width: '100%' }} disabled />
                            </Form.Item>
                          </div>
                          <div
                            className={c.stakingInfo__item}
                            style={{ width: '18%' }}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="lowestPriceIn24h"
                              label="Lowest Price In 24h"
                              initialValue={singleData.lowestPriceIn24h}>
                              <InputNumber style={{ width: '100%' }} disabled />
                            </Form.Item>
                          </div>
                          <div
                            className={c.stakingInfo__item}
                            style={{ width: '18%' }}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="highestPriceIn24h"
                              label="Highest Price In 24h"
                              initialValue={singleData.highestPriceIn24h}>
                              <InputNumber style={{ width: '100%' }} disabled />
                            </Form.Item>
                          </div>
                          <div
                            className={c.stakingInfo__item}
                            style={{ width: '18%' }}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="marketCap"
                              label="Market Cap"
                              initialValue={singleData.marketCap}>
                              <InputNumber style={{ width: '100%' }} disabled />
                            </Form.Item>
                          </div>
                        </div>
                      )}*/}
                    </div>
                  </div>
                </div>
                <div className={c.aside}>
                  <div className={c.aside__inner}>
                    <div className={c.aside__item}>
                      <Form.Item
                        style={{
                          flexDirection: 'column',
                        }}
                        name="status"
                        label="Status"
                        initialValue={singleData.status}>
                        <Select
                          placeholder="select status"
                          onChange={e => {
                            setListingStatus(e);
                            setRejectReasonOption('');
                            setRejectReason('');
                          }}>
                          {selectOptions?.status?.map(item => (
                            <Option key={item.value} value={item.value}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    {rejectReason && (
                      <div className={c.aside__item}>
                        <div className={c.aside__itemTitle}>Reject Reason</div>
                        <div
                          style={{ fontSize: '12px', marginBottom: '1.5rem' }}>
                          {rejectReason}
                        </div>
                      </div>
                    )}
                    <div className={c.aside__item}>
                      <Form.Item
                        name="created"
                        style={{
                          flexDirection: 'column',
                        }}
                        label="Created"
                        initialValue={
                          singleData.created
                            ? moment(singleData.created)
                            : singleData.created
                        }>
                        <DatePicker style={{ width: '100%' }} disabled />
                      </Form.Item>
                    </div>
                    <div className={c.aside__item}>
                      <Form.Item
                        style={{
                          flexDirection: 'column',
                        }}
                        name="addToVoting"
                        label="Add to voting"
                        initialValue={singleData.addToVoting}>
                        <Radio.Group>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    <div className={c.aside__item}>
                      <Form.Item
                        name="addedToVotingDate"
                        style={{
                          flexDirection: 'column',
                        }}
                        label="Added on Voting"
                        initialValue={
                          singleData.addedToVotingDate
                            ? moment(singleData.addedToVotingDate)
                            : singleData.addedToVotingDate
                        }>
                        <DatePicker style={{ width: '100%' }} />
                      </Form.Item>
                    </div>
                    <div className={c.aside__item}>
                      <Form.Item
                        name="listedDate"
                        style={{
                          flexDirection: 'column',
                        }}
                        label="Listed on Bololex"
                        initialValue={
                          singleData.listedDate
                            ? moment(singleData.listedDate)
                            : singleData.listedDate
                        }>
                        <DatePicker style={{ width: '100%' }} />
                      </Form.Item>
                    </div>
                    <div className={c.aside__item}>
                      <Form.Item
                        style={{
                          flexDirection: 'column',
                        }}
                        name="listingType"
                        label="Type"
                        initialValue={singleData.listingType}>
                        <Select placeholder="select type">
                          {selectOptions?.listingTypes?.map(item => (
                            <Option key={item.value} value={item.value}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className={c.aside__item}>
                      <Form.Item
                        style={{
                          flexDirection: 'column',
                        }}
                        name="listingPackage"
                        label="Package"
                        initialValue={singleData.listingPackage}>
                        <Select placeholder="select ">
                          {selectOptions?.listingPackage?.map(item => (
                            <Option key={item.value} value={item.value}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className={c.aside__item}>
                      <Form.Item
                        style={{
                          flexDirection: 'column',
                        }}
                        label={'Payment Amount (USDT)'}
                        name="paymentAmount"
                        validateTrigger="onBlur"
                        initialValue={singleData.paymentAmount}
                        rules={[
                          {
                            required: false,
                            message: 'Required',
                          },
                          {
                            message: validateMsg('min', 0),
                            validator: validateMinAmount(0),
                          },
                          {
                            message: validateMsg('max', 1000000000),
                            validator: validateMaxAmount(1000000000),
                          },
                        ]}>
                        <NumericInput
                          precision={6}
                          disabled={singleData?.order?.status === 'paid'}
                          onDoubleClick={CopyToClipboard}
                          onBlur={currentValue =>
                            onBlur(currentValue, 'paymentAmount')
                          }
                        />
                      </Form.Item>
                    </div>
                    {!!singleData.totalPaymentAmount && (
                      <div className={c.aside__item}>
                        <div className={c.aside__itemTitle}>
                          Total Amount (USDT)
                        </div>
                        <div>
                          {!singleData.totalPaymentAmount
                            ? singleData.totalPaymentAmount
                            : amountPrecision(singleData.totalPaymentAmount)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {rejectPopup && (
                <Modal
                  title={'Reject Reason'}
                  onOk={() => setRejectPopup(false)}>
                  <div
                    className={c.stakingInfo__item}
                    style={{ width: '100%' }}>
                    <Select
                      style={{ width: '300px' }}
                      defaultValue={selectOptions?.rejectReason[0].value}
                      onChange={e => {
                        setRejectReason(e);
                        setRejectReasonOption(e);
                      }}>
                      {selectOptions?.rejectReason?.map(item => (
                        <Option key={item.value} value={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  {rejectReasonOption === 'other' && (
                    <div
                      className={c.stakingInfo__item}
                      style={{ width: '100%', marginTop: '1rem' }}>
                      <TextArea
                        initialValue={singleData.name}
                        placeholder="Controlled autosize"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        onChange={e => setRejectReason(e.target.value)}
                      />
                    </div>
                  )}
                </Modal>
              )}
            </Form>
            {!create && (
              <Comments
                userComments={userComments}
                systemComments={systemComments}
                setUserComments={setUserComments}
                setSystemComments={setSystemComments}
                getSingleData={getSingleData}
                id={params.id}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ListingEdit;
