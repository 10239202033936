import React, { useEffect } from 'react';
import c from './Model.module.scss';
import { Button } from 'antd';

const Modal = ({ children, title, onOk, onClose }) => {
  useEffect(() => {
    document.documentElement.classList.add('dontScroll');
    return () => {
      document.documentElement.classList.remove('dontScroll');
    };
  }, []);

  return (
    <div className={c.modal}>
      <div className={c.modal__close} onClick={onClose} />
      <div className={c.modal__body}>
        {title && <div className={c.modal__title}>{title}</div>}
        <div className={c.modal__inner}>{children}</div>
        <div className={c.modal__ok}>
          <Button type="primary" onClick={onOk}>
            Save
          </Button>
          {onClose && (
            <Button
              onClick={onClose}
              type="primary"
              style={{ marginLeft: '10px' }}>
              Cancel
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
