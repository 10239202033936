import React, { useEffect, useState } from 'react';
import {
  AutoComplete,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
} from 'antd';

import c from './StakingForm.module.scss';
import {
  LeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  ReloadOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  apiRequestSelectOptions,
  apiEditRequestById,
  apiEditStakingStatusById,
  apiGetRequestById,
  apiRequestCreate,
  apiSearchUser,
} from '../../../store/slices/requestSlice/requestAPI';
import classNames from 'classnames';
import moment from 'moment';
import Loader from '../../Loader/Loader';
import Comments from '../../Comments/Comments';
import Modal from '../../Modal/Modal';
import { CopyToClipboard } from '../../../helper/copyToClipboard';
import { amountPrecision } from '../../../helper/utl';
import NumericInput from '../../Ui/NumericInput/NumericInput';

const StakingForm = ({ stakingProgramId }) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const { Option } = Select;
  const [singleData, setSingleData] = useState();
  const navigate = useNavigate();
  const [selectOptions, setSelectOptions] = useState([]);
  const [relationWithCoinOption, setRelationWithCoinOption] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userComments, setUserComments] = useState([]);
  const [systemComments, setSystemComments] = useState([]);
  const [totalCollateral, setTotalCollateral] = useState(0);
  const [availablePeriodWithOther, setAvailablePeriodWithOther] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [listingStatus, setListingStatus] = useState();
  const [rejectPopup, setRejectPopup] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [rejectReasonOption, setRejectReasonOption] = useState('');

  useEffect(() => {
    if (listingStatus === 'rejected') {
      setRejectPopup(true);
      setRejectReason(selectOptions?.rejectReason[0].value);
      setRejectReasonOption(selectOptions?.rejectReason[0].value);
    } else {
      setRejectPopup(false);
    }
  }, [listingStatus]);

  const onBlur = () => {};

  const validateMsg = (type, value) => {
    let message = '';
    if (type === 'min') {
      message = `Should be more than ${value}`;
      return message;
    } else if (type === 'max') {
      message = `should not be more than ${value}`;
      return message;
    }
  };

  const validateMinAmount = min => {
    return (_, value) => {
      if (value >= min) {
        return Promise.resolve();
      } else {
        return Promise.reject(_.message);
      }
    };
  };

  const validateMaxAmount = max => {
    return (_, value) => {
      if (value <= max) {
        return Promise.resolve();
      } else {
        return Promise.reject(_.message);
      }
    };
  };

  const getStakingById = async () => {
    setLoading(true);
    console.log(stakingProgramId);
    if (!stakingProgramId) {
      setLoading(false);
      return;
    }
    await apiGetRequestById(stakingProgramId)
      .then(e => {
        e.created = e.created ? moment(e.created) : e.created;
        e.extra.staking.items?.forEach(item => {
          item.desiredStartDate = item.desiredStartDate
            ? moment(item.desiredStartDate)
            : item.desiredStartDate;
          item.activationStartDate = item.activationStartDate
            ? moment(item.activationStartDate)
            : item.activationStartDate;
        });
        if (e.comments?.length) {
          setUserComments(e.comments.filter(item => item.type === 'user'));
          setSystemComments(e.comments.filter(item => item.type === 'system'));
        }

        e.extra.staking.items.forEach(e => {
          if (e.availablePeriod.days === 'other') {
            setAvailablePeriodWithOther(prevState => [...prevState, e._id]);
          }
        });
        setRejectReason(e.extra.staking.rejectReason);
        setTotalCollateral(e.totalCollateral);
        setRelationWithCoinOption(e.relationWithCoin.relation);
        setSingleData(e);
        setSelectedUser(e.user);
      })
      .finally(() => setLoading(false));
  };

  const editData = async stakingData => {
    const values = JSON.parse(JSON.stringify(stakingData));
    values.extra.staking.items?.forEach(item => {
      item.status = undefined;
      item.programId = undefined;
      item.desiredStartDate = item.desiredStartDate
        ? moment(item.desiredStartDate).unix() * 1000
        : null;
      item.activationStartDate = item.activationStartDate
        ? moment(item.activationStartDate).unix() * 1000
        : null;
      item.activationEndDate = undefined;
    });
    const data = {
      ...values,
      type: 'staking',
      created: undefined,
    };
    data.user = selectedUser;

    if (stakingProgramId) {
      data.extra.staking.rejectReason = rejectReason;
      await apiEditRequestById(stakingProgramId, data)
        .then(e => {
          toast.success('Save');
          form.setFieldsValue({ ...e });
          getStakingById();
        })
        .catch(e => toast.error(e.message));
    } else {
      await apiRequestCreate({
        ...data,
      })
        .then(() => {
          toast.success('Save');
          navigate('/staking');
        })
        .catch(e => toast.error(e.message));
    }
  };

  useEffect(async () => {
    await getStakingById();
    await apiRequestSelectOptions()
      .then(e => setSelectOptions(e))
      .catch(e => console.log(e));
  }, []);

  const editStakingStatusById = async (index, status) => {
    await apiEditStakingStatusById(stakingProgramId, index, status).then(
      async () => await getStakingById(),
    );
  };

  const sumCollaterals = allFields => {
    setTotalCollateral(
      allFields
        .filter(field => field.name[field.name.length - 1] === 'collateral')
        .reduce((acc, item) => acc + item.value, 0),
    );
  };

  const checkAvailablePeriod = () => {
    setAvailablePeriodWithOther([]);
    const fields = form.getFieldsValue();
    fields?.extra?.staking?.items?.forEach((e, index) => {
      if (e.availablePeriod.days === 'other') {
        setAvailablePeriodWithOther(prevState => [
          ...prevState,
          e._id ? e._id : index,
        ]);
      }
    });
  };

  const onSearch = async searchText => {
    if (searchText.length < 3) {
      setUserList([]);
      return;
    }
    await apiSearchUser(searchText).then(e =>
      setUserList(
        e.users.map(item => {
          return {
            value: item.username + '(' + item.email + ')',
            email: item.email,
            id: item.id,
            role: item.role,
            username: item.username,
          };
        }),
      ),
    );
  };

  const onSelect = (_, data) => {
    delete data.value;
    setSelectedUser(data);
  };

  return (
    <>
      <h1 className="title">Staking Single</h1>
      {loading ? (
        <Loader />
      ) : (
        <div className={c.stakingForm}>
          <Form
            form={form}
            name="stakingEdit"
            onFinish={editData}
            autoComplete="off"
            initialValues={singleData}
            onFieldsChange={(_, allFields) => {
              sumCollaterals(allFields);
            }}
            scrollToFirstError>
            <div className={c.single}>
              <div className={c.stakingInfo}>
                <div className={c.stakingInfo__buttons}>
                  <Button
                    type="primary"
                    icon={<LeftOutlined />}
                    onClick={() => navigate(-1)}>
                    Back
                  </Button>
                  <Button
                    type="primary"
                    icon={<ReloadOutlined />}
                    onClick={() => form.resetFields()}>
                    Reset
                  </Button>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      icon={<SaveOutlined />}>
                      Save
                    </Button>
                  </Form.Item>
                </div>
                <div className={c.stakingInfo__list}>
                  <div className={c.stakingInfo__item}>
                    <Form.Item
                      style={{
                        flexDirection: 'column',
                      }}
                      label="User">
                      <AutoComplete
                        options={userList}
                        notFoundContent={'Nothing found'}
                        onSelect={onSelect}
                        onSearch={onSearch}
                        placeholder="Search User"
                        defaultValue={
                          singleData?.user
                            ? singleData.user.username +
                              '(' +
                              singleData.user.email +
                              ')'
                            : ''
                        }
                      />
                    </Form.Item>
                  </div>
                  <div className={c.stakingInfo__item}>
                    <Form.Item
                      style={{
                        flexDirection: 'column',
                      }}
                      name="contactEmail"
                      label="Contact Email"
                      rules={[
                        {
                          type: 'email',
                        },
                        {
                          required: true,
                          message: 'Required',
                        },
                      ]}>
                      <Input onDoubleClick={CopyToClipboard} />
                    </Form.Item>
                  </div>
                  <div className={c.stakingInfo__item}>
                    <Form.Item
                      style={{
                        flexDirection: 'column',
                      }}
                      name="code"
                      label="Symbol"
                      rules={[
                        {
                          required: true,
                          message: 'Required',
                          whitespace: true,
                        },
                      ]}>
                      <Select
                        placeholder="Select Code"
                        style={{ width: '100%' }}>
                        {selectOptions?.availableCurrencies?.map(item => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className={c.stakingInfo__item}>
                    <Form.Item
                      style={{
                        flexDirection: 'column',
                      }}
                      name="telegram"
                      label="Telegram">
                      <Input onDoubleClick={CopyToClipboard} />
                    </Form.Item>
                  </div>
                  <div
                    className={c.stakingInfo__item}
                    style={{ width: '48.6%' }}>
                    <div className={c.aside__itemTitle}>
                      <span
                        style={{
                          display: 'inline-block',
                          fontFamily: 'SimSun, sans-serif',
                          color: '#ff4d4f',
                          fontSize: '14px',
                          marginRight: '5px',
                        }}>
                        *
                      </span>
                      Relations with the C/T:
                    </div>
                    <Form.Item style={{ width: '100%' }}>
                      <Input.Group style={{ display: 'flex', width: '100%' }}>
                        <Form.Item
                          style={{
                            width: '100%',
                            marginBottom: 0,
                          }}
                          rules={[
                            {
                              required: true,
                              message: 'Required',
                            },
                          ]}
                          name={['relationWithCoin', 'relation']}>
                          <Select
                            placeholder="Select province"
                            style={{ width: '100%' }}
                            onChange={e => {
                              setRelationWithCoinOption(e);
                              form.setFieldsValue({
                                relationWithCoin: { value: null },
                              });
                            }}>
                            {selectOptions?.relationWithCoin?.map(item => (
                              <Option key={item.value} value={item.value}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        {relationWithCoinOption === 'other' && (
                          <Form.Item
                            style={{
                              width: '100%',
                              marginBottom: 0,
                            }}
                            name={['relationWithCoin', 'value']}
                            rules={[
                              {
                                required: relationWithCoinOption === 'other',
                                message: 'Required',
                              },
                            ]}>
                            <Input
                              style={{ width: '100%' }}
                              placeholder="Input Relation"
                            />
                          </Form.Item>
                        )}
                      </Input.Group>
                    </Form.Item>
                  </div>
                  <div
                    className={c.stakingInfo__item}
                    style={{ width: '100%' }}>
                    <Form.Item
                      style={{
                        flexDirection: 'column',
                      }}
                      name={['extra', 'staking', 'info']}
                      label="Any other information that you would like to provide?"
                      rules={[
                        {
                          required: false,
                        },
                      ]}>
                      <TextArea
                        placeholder="Controlled autosize"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        onDoubleClick={CopyToClipboard}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className={c.stakingInfo__list}>
                  <Form.List
                    name={['extra', 'staking', 'items']}
                    initialValue={[{}]}>
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(item => (
                          <Space
                            key={item.key}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              marginTop: 10,
                              paddingTop: 16,
                              borderTop: '1px solid silver',
                              flexWrap: 'wrap',
                              width: '100%',
                              rowGap: 0,
                            }}
                            align="baseline">
                            <h3>Staking Program {item.key + 1}</h3>
                            <div
                              className={classNames(
                                c.stakingInfo__item,
                                c.big,
                              )}>
                              <Form.Item
                                style={{
                                  flexDirection: 'column',
                                  width: '100%',
                                }}
                                name={[item.name, 'desiredPeriod']}
                                label="Desired Period"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Required',
                                  },
                                ]}>
                                <Select placeholder="select your Desired Period">
                                  {selectOptions?.desiredPeriod?.map(item => (
                                    <Option key={item} value={item}>
                                      {item}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                            <div
                              className={classNames(
                                c.stakingInfo__item,
                                c.big,
                              )}>
                              <Form.Item
                                style={{
                                  flexDirection: 'column',
                                }}
                                name={[item.name, 'interestRate']}
                                label="Interest Rate"
                                validateTrigger="onBlur"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Required',
                                  },
                                  {
                                    message: validateMsg('min', 10 ** -6),
                                    validator: validateMinAmount(10 ** -6),
                                  },
                                  {
                                    message: validateMsg('max', 1000000000),
                                    validator: validateMaxAmount(1000000000),
                                  },
                                ]}>
                                <NumericInput
                                  precision={6}
                                  onDoubleClick={CopyToClipboard}
                                  onBlur={currentValue =>
                                    onBlur(currentValue, 'interestRate')
                                  }
                                />
                              </Form.Item>
                            </div>
                            <div
                              className={classNames(
                                c.stakingInfo__item,
                                c.big,
                              )}>
                              <Form.Item
                                style={{
                                  flexDirection: 'column',
                                }}
                                name={[item.name, 'collateral']}
                                label="Collateral"
                                validateTrigger="onBlur"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Required',
                                  },
                                  {
                                    message: validateMsg('min', 10 ** -6),
                                    validator: validateMinAmount(10 ** -6),
                                  },
                                  {
                                    message: validateMsg(
                                      'max',
                                      999999999999999999999,
                                    ),
                                    validator:
                                      validateMaxAmount(999999999999999999999),
                                  },
                                ]}>
                                <NumericInput
                                  precision={6}
                                  onDoubleClick={CopyToClipboard}
                                  onBlur={currentValue =>
                                    onBlur(currentValue, 'collateral')
                                  }
                                />
                              </Form.Item>
                            </div>
                            <div
                              className={classNames(
                                c.stakingInfo__item,
                                c.big,
                              )}>
                              <Form.Item
                                style={{
                                  flexDirection: 'column',
                                }}
                                name={[item.name, 'minAmount']}
                                label="Min Amount"
                                validateTrigger="onBlur"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Required',
                                  },
                                  {
                                    message: validateMsg('min', 10 ** -6),
                                    validator: validateMinAmount(10 ** -6),
                                  },
                                  {
                                    message: validateMsg('max', 1000000000),
                                    validator: validateMaxAmount(1000000000),
                                  },
                                ]}>
                                <NumericInput
                                  precision={6}
                                  onDoubleClick={CopyToClipboard}
                                  onBlur={currentValue =>
                                    onBlur(currentValue, 'minAmount')
                                  }
                                />
                              </Form.Item>
                            </div>
                            <div
                              className={classNames(
                                c.stakingInfo__item,
                                c.big,
                              )}>
                              <Form.Item
                                style={{
                                  flexDirection: 'column',
                                }}
                                name={[item.name, 'maxAmount']}
                                label="Max Amount"
                                validateTrigger="onBlur"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Required',
                                  },
                                  {
                                    message: validateMsg('min', 10 ** -6),
                                    validator: validateMinAmount(10 ** -6),
                                  },
                                  {
                                    message: validateMsg('max', 1000000000),
                                    validator: validateMaxAmount(1000000000),
                                  },
                                ]}>
                                <NumericInput
                                  precision={6}
                                  onDoubleClick={CopyToClipboard}
                                  onBlur={currentValue =>
                                    onBlur(currentValue, 'maxAmount')
                                  }
                                />
                              </Form.Item>
                            </div>
                            <div
                              className={classNames(
                                c.stakingInfo__item,
                                c.big,
                              )}>
                              <Form.Item
                                name={[item.name, 'desiredStartDate']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Required',
                                  },
                                ]}
                                style={{
                                  flexDirection: 'column',
                                }}
                                label="Desired Start Date">
                                <DatePicker
                                  format={'YYYY/MM/DD'}
                                  style={{ width: '100%' }}
                                />
                              </Form.Item>
                            </div>
                            <div
                              className={classNames(
                                c.stakingInfo__item,
                                c.big,
                              )}>
                              <div className={c.aside__itemTitle}>
                                <span
                                  style={{
                                    display: 'inline-block',
                                    fontFamily: 'SimSun, sans-serif',
                                    color: '#ff4d4f',
                                    fontSize: '14px',
                                    marginRight: '5px',
                                  }}>
                                  *
                                </span>
                                Available Period
                              </div>
                              <Form.Item style={{ width: '100%' }}>
                                <Input.Group
                                  style={{ display: 'flex', width: '100%' }}>
                                  <Form.Item
                                    style={{
                                      width: '100%',
                                      marginBottom: 0,
                                    }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Required',
                                      },
                                    ]}
                                    name={[
                                      item.name,
                                      'availablePeriod',
                                      'days',
                                    ]}>
                                    <Select
                                      placeholder="Select province"
                                      style={{ width: '100%' }}
                                      onChange={() => checkAvailablePeriod()}>
                                      {selectOptions?.availablePeriod?.map(
                                        item => (
                                          <Option
                                            key={item.value}
                                            value={item.value}>
                                            {item.name}
                                          </Option>
                                        ),
                                      )}
                                    </Select>
                                  </Form.Item>
                                  {availablePeriodWithOther.some(e =>
                                    singleData?.extra?.staking?.items[item.name]
                                      ?._id
                                      ? e ===
                                        singleData.extra?.staking?.items[
                                          item.name
                                        ]?._id
                                      : e === item.name,
                                  ) && (
                                    <Form.Item
                                      style={{
                                        width: '100%',
                                        marginBottom: 0,
                                      }}
                                      name={[
                                        item.name,
                                        'availablePeriod',
                                        'value',
                                      ]}
                                      rules={[
                                        {
                                          required:
                                            availablePeriodWithOther.some(e =>
                                              singleData?.extra?.staking?.items[
                                                item.name
                                              ]?._id
                                                ? e ===
                                                  singleData.extra?.staking
                                                    ?.items[item.name]?._id
                                                : e === item.name,
                                            ),
                                          message: 'Required',
                                        },
                                      ]}>
                                      <InputNumber
                                        min={1}
                                        style={{ width: '100%' }}
                                        placeholder="Input Relation"
                                      />
                                    </Form.Item>
                                  )}
                                </Input.Group>
                              </Form.Item>
                            </div>
                            <div
                              className={classNames(
                                c.stakingInfo__item,
                                c.big,
                              )}>
                              <Form.Item
                                name={[item.name, 'activationStartDate']}
                                style={{
                                  flexDirection: 'column',
                                }}
                                label="Activation Start Date">
                                <DatePicker
                                  format={'YYYY/MM/DD'}
                                  style={{ width: '100%' }}
                                />
                              </Form.Item>
                            </div>
                            <div
                              className={classNames(
                                c.stakingInfo__item,
                                c.big,
                              )}>
                              <div className={c.aside__itemTitle}>
                                Activation End Date
                              </div>
                              <div style={{ marginBottom: 28 }}>
                                <DatePicker
                                  format={'YYYY/MM/DD'}
                                  style={{ width: '100%' }}
                                  disabled
                                  defaultValue={
                                    singleData?.extra?.staking?.items[item.name]
                                      ?.activationEndDate
                                      ? moment(
                                          singleData.extra.staking.items[
                                            item.name
                                          ].activationEndDate,
                                        )
                                      : null
                                  }
                                />
                              </div>
                            </div>
                            {singleData?.extra?.staking?.items[item.name] ? (
                              <div
                                className={classNames(
                                  c.stakingInfo__item,
                                  c.big,
                                )}>
                                {singleData?.extra?.staking?.items[item.name]
                                  ?.status === 'new' ? (
                                  <div>
                                    <div className={c.aside__itemTitle}>
                                      Status
                                    </div>
                                    <div className={c.stakingInfo__actions}>
                                      <Button
                                        type="primary"
                                        htmlType={'button'}
                                        onClick={() =>
                                          editStakingStatusById(
                                            item.name,
                                            'created',
                                          )
                                        }>
                                        Create
                                      </Button>
                                      <Button
                                        type="primary"
                                        htmlType={'button'}
                                        danger
                                        onClick={() =>
                                          editStakingStatusById(
                                            item.name,
                                            'rejected',
                                          )
                                        }>
                                        Reject
                                      </Button>
                                    </div>
                                  </div>
                                ) : (
                                  <div>
                                    <div className={c.aside__itemTitle}>
                                      Status
                                    </div>
                                    <div
                                      className={classNames(
                                        c.stakingInfo__actions,
                                        c[
                                          singleData?.extra?.staking?.items[
                                            item.name
                                          ]?.status
                                        ],
                                      )}>
                                      {
                                        singleData?.extra?.staking?.items[
                                          item.name
                                        ]?.status
                                      }
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <MinusCircleOutlined
                                onClick={() => remove(item.name)}
                                style={{ display: 'flex' }}
                              />
                            )}
                            <div
                              className={classNames(c.stakingInfo__item, c.big)}
                            />
                          </Space>
                        ))}
                        <Form.Item style={{ width: '100%' }}>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}>
                            Add Program
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </div>
              </div>
              <div className={c.aside}>
                <div className={c.aside__inner}>
                  <div className={c.aside__item}>
                    <Form.Item
                      style={{
                        flexDirection: 'column',
                      }}
                      name="paymentAmount"
                      label="Payment Amount"
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: 'Required',
                        },
                        {
                          message: validateMsg('min', 10 ** -6),
                          validator: validateMinAmount(10 ** -6),
                        },
                        {
                          message: validateMsg('max', 1000000000),
                          validator: validateMaxAmount(1000000000),
                        },
                      ]}>
                      <NumericInput
                        precision={6}
                        onDoubleClick={CopyToClipboard}
                        disabled={singleData?.order?.status === 'paid'}
                        onBlur={currentValue =>
                          onBlur(currentValue, 'maxAmount')
                        }
                      />
                    </Form.Item>
                  </div>
                  {stakingProgramId && (
                    <div className={c.aside__item}>
                      <Form.Item
                        name={'created'}
                        style={{
                          flexDirection: 'column',
                        }}
                        label="Created">
                        <DatePicker style={{ width: '100%' }} disabled />
                      </Form.Item>
                    </div>
                  )}
                  <div className={c.aside__item}>
                    <Form.Item
                      style={{
                        flexDirection: 'column',
                      }}
                      name={['extra', 'staking', 'processingStatus']}
                      label="Processing Status">
                      <Select
                        placeholder="select your Processing Status"
                        onChange={e => {
                          setListingStatus(e);
                          setRejectReasonOption('');
                          setRejectReason('');
                        }}>
                        {selectOptions?.processingStatus?.map(item => (
                          <Option
                            key={item.value}
                            value={item.value}
                            disabled={item.disabled}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  {rejectReason && (
                    <div className={c.aside__item}>
                      <div className={c.aside__itemTitle}>Reject Reason</div>
                      <div style={{ fontSize: '12px', marginBottom: '1.5rem' }}>
                        {rejectReason}
                      </div>
                    </div>
                  )}
                  <div className={c.aside__item}>
                    <div className={c.aside__itemTitle}>
                      <span
                        style={{
                          display: 'inline-block',
                          fontFamily: 'SimSun, sans-serif',
                          color: '#ff4d4f',
                          fontSize: '14px',
                          marginRight: '5px',
                        }}>
                        *
                      </span>
                      Total Colleteral
                    </div>
                    <div>
                      {!totalCollateral ? 0 : amountPrecision(totalCollateral)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
          {rejectPopup && (
            <Modal title={'Reject Reason'} onOk={() => setRejectPopup(false)}>
              <div className={c.stakingInfo__item} style={{ width: '100%' }}>
                <Select
                  style={{ width: '300px' }}
                  defaultValue={selectOptions?.rejectReason[0].value}
                  onChange={e => {
                    setRejectReason(e);
                    setRejectReasonOption(e);
                  }}>
                  {selectOptions?.rejectReason?.map(item => (
                    <Option key={item.value} value={item.value}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
              {rejectReasonOption === 'other' && (
                <div
                  className={c.stakingInfo__item}
                  style={{ width: '100%', marginTop: '1rem' }}>
                  <TextArea
                    initialValue={singleData.name}
                    placeholder="Controlled autosize"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    onChange={e => setRejectReason(e.target.value)}
                  />
                </div>
              )}
            </Modal>
          )}
          {stakingProgramId && (
            <Comments
              userComments={userComments}
              systemComments={systemComments}
              setUserComments={setUserComments}
              setSystemComments={setSystemComments}
              getSingleData={getStakingById}
              id={stakingProgramId}
              requests
            />
          )}
        </div>
      )}
    </>
  );
};

export default StakingForm;
